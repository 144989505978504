.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}

.experience__container > div {
  background: transparent;
  padding: 2.4rem 2rem;
  border-radius: 2rem;
  border: 1px solid var(--color-primary-variant);
  transition: var(--transition);
}

.experience__container > div:hover {
  background: transparent;
  cursor: default;
  transform: translateY(-5px);
  box-shadow: inset 0px 10px 20px 2px rgba(249, 65, 68, 0.7);
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-white);
}

.experience__content h4 {
  color: var(--color-white);
}

.experience__content small {
  color: var(--color-light);
}
.experience__content {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
}

.experience__details {
  display: flex;
  gap: 1rem;
}

.experience__details-icon {
  font-size: 1.3rem;
  margin-top: 6px;
  color: var(--color-primary-variant);
}

@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }

  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .experience__content {
    padding: 1rem;
  }

  .experience__details {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
  }

  .experience__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
}
