.NotFoundPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  .NotFoundPage h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
  }
  
  .NotFoundPage p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .NotFoundPage a {
    font-size: 1.2rem;
    color: #fff;
    background-color: #000;
    padding: 1rem;
    border-radius: 0.5rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }
  
  .NotFoundPage a:hover {
    background-color: #fff;
    color: #000;
  }
  