:root {
  --swiper-theme-color: var(--color-primary);
}
.swiper-pagination-bullet {
  background: var(--color-white);
}

.swiper-pagination-bullet-active {
  background: var(--color-primary);
}

.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2.5rem;
}
.portfolio__item {
  background: transparent;
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid var(--color-primary-variant);
  transition: var(--transition);
}

.portfolio__item:hover {
  transform: translateY(5px);
  box-shadow: inset 0px 10px 20px 2px rgba(249, 65, 68, 0.7);
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item-image img {
  height: 400px;
  object-fit: cover;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-flow: row wrap;
}

.portfolio__container-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    gap: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .portfolio__container {
    display: none;
  }
  .portfolio__container-mobile {
    display: block;
  }
}
