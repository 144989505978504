header {
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  position: relative;
}

.cv {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.socials {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  position: fixed;
  left: 3rem;
  bottom: 0;
}

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 1rem;
}

.socials::after {
  content: '';
  border: 0.5px solid var(--color-primary);
  height: 5rem;
}

@media screen and (max-width: 600px) {
  .socials,
  .scroll__down {
    display: none;
  }
}