@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  z-index: 10;
}

:root {
  --color-bg: #011627;
  --color-bg-variant: #2ec4b6;
  --color-icon: #011627;
  --color-primary: #2ec4b6;
  --color-white: #fdfffc;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-primary-variant: #e71d36;
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --transition: all 400ms ease;
  border: 2px solid var(--color-primary-variant);
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

.wrapper {
}

body {
  background-image: url("./assets/bg.png");
  font-family: "Poppins", sans-serif;
  background-color: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  background: linear-gradient(
    90deg,
    rgba(46, 196, 182, 1) 0%,
    rgba(255, 159, 28, 1) 59%,
    rgba(231, 29, 54, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2,
section > h5 {
  text-align: center;
}

section > h2 {
  background: linear-gradient(
    90deg,
    rgba(46, 196, 182, 1) 0%,
    rgba(255, 159, 28, 1) 59%,
    rgba(231, 29, 54, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 3rem;
}

section > h5 {
  color: var(--color-light);
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover,
a:hover {
  border-color: var(--color-primary-variant);
  background: linear-gradient(
    90deg,
    rgba(46, 196, 182, 1) 0%,
    rgba(255, 159, 28, 1) 59%,
    rgba(231, 29, 54, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-primary {
  background-image: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  padding: 3px;
  animation: animatedBorder 0.3s ease alternate infinite;
  border: transparent;
}

.btn-primary span {
  display: inline-block;
  background: var(--color-bg);
  padding: 0.75rem 1.2rem;
  border-radius: 5px;
  height: 100%;
}

.btn-primary:hover {
  -webkit-text-fill-color: var(--color-bg-variant);
  -webkit-background-clip: unset;
  background-clip: unset;
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.logo {
  background: var(--color-white);
  width: 12rem;
  display: inline-block;
  border-radius: 20px;
  text-align: center;
}
@keyframes animatedBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
  section > h2 {
    margin-bottom: 2rem;
  }
}
